<template>
    <div class="AuxilLedgerList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="deviceCrkFram" ref="deviceCrkFram">
                    <el-form-item label="类型">
                        <el-select v-model="deviceCrkFram.type">
                            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name"
                                :value="item.type"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="仪器条码">
                        <el-input v-model="deviceCrkFram.deviceCode" placeholder="请输入条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器分类">
                        <el-select placeholder="请选择仪器分类" v-model="deviceCrkFram.deviceOneClass">
                            <el-option v-for="(item, index) in deviceClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="出入库类型">
                        <el-select placeholder="请选择仪器分类" v-model="deviceCrkFram.crkType">
                            <el-option v-for="(item, index) in crkTypeList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="仪器实际名称">
                        <el-input v-model="deviceCrkFram.deviceRealName" placeholder="请输入仪器实际名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="经办人">
                        <el-input v-model="deviceCrkFram.crkJbr" placeholder="请输入出/入库经办人查询"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="出库经办人">
                        <el-input v-model="deviceCrkFram.ckJbr" placeholder="请输入出库经办人查询"></el-input>
                    </el-form-item>
                    <el-form-item label="入库经办人">
                        <el-input v-model="deviceCrkFram.rkJbr" placeholder="请输入入库经办人查询"></el-input>
                    </el-form-item> -->

                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <!-- $router.push({ name: 'auxil-oi-ckmanage' }) -->
                    <el-button class="btn" @click="$router.push({ name: 'device-oi-ckmanage' })"
                        icon="el-icon-truck">出库</el-button>
                    <!-- $router.push({ name: 'auxil-oi-rkmanage' }) -->
                    <el-button type="warning" @click="$router.push({ name: 'device-oi-rkmanage' })"
                        icon="el-icon-truck">入库</el-button>
                    <el-button type="primary" @click="handleDown" icon="el-icon-upload2">导出</el-button>

                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceCrkList" border style="width: 100%; font-size: 13px;" v-loading="loading" :cell-style="bgColor"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="oddNum" label="批次号" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center"> </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center"> </el-table-column>
                <el-table-column prop="deviceCode" label="仪器条码" align="center"> </el-table-column>
                <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                <el-table-column prop="crkTypeName" label="出库类型" align="center">
                </el-table-column>
                <el-table-column prop="status_text" label="出库状态" align="center">
                </el-table-column>
                <el-table-column prop="ckJbr" label="出库经办人" align="center">
                </el-table-column>
                <el-table-column prop="ckDate" label="出库日期" align="center">
                </el-table-column>
                <el-table-column prop="ckTime" label="出库时间" align="center">
                </el-table-column>
                <el-table-column prop="rkJbr" label="入库经办人" align="center">
                </el-table-column>
                <el-table-column prop="rkDate" label="入库日期" align="center">
                </el-table-column>
                <el-table-column prop="rkTime" label="入库时间" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="120px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="延期入库"
                            v-if="deviceCrkFram.type === '00' && scope.row.crkType === '00'"
                            @click="handleDelay(scope.row)">延期入库</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceCrkFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="deviceCrkFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>

        <!-- 新增表单 -->
        <el-dialog title="延期入库" :visible.sync="dialogFormVisible" width="500px" :before-close="closeInfo"
            :close-on-click-modal="false">
            <el-form :model="deviceCrkInfo" :rules="rules" ref="deviceCrkInfo">
                <el-form-item label="延期入库日期" :label-width="formLabelWidth" prop="useDeadline">
                    <el-date-picker v-model="deviceCrkInfo.useDeadline" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        type="date" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="延期原因" :label-width="formLabelWidth" prop="reason">
                    <el-input v-model="deviceCrkInfo.reason" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('deviceCrkInfo')">取 消</el-button>
                <el-button class="btn" @click="sure('deviceCrkInfo')">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>

import { search, crkTypeList, rkDetail } from '@/api/deviceCrk.js'
import { downloadExcel } from '@/api/download.js'
import { getDeviceClassByLevel } from '@/api/deviceClass.js'
import {crkStatusList} from '@/api/device.js'
export default {

    data() {
        return {
            crkStatusList: crkStatusList,
            deviceCrkList: [],
            deviceClassList: [],
            crkTypeList: crkTypeList,
            deviceCrkFram: {
                type: '00',
                deviceCode: null,
                deviceOneClass: null,
                crkType: null,
                deviceRealName: '',
                crkJbr: null,
                ckJbr: null,
                rkJbr: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false,
            typeList: [{ type: '00', name: '当前出入库记录' }, { type: '01', name: '历史出入库记录' }],
            dialogFormVisible: false,
            deviceCrkInfo: {
                id: null,
                deviceCode: null,
                useDeadline: null,
                reason: null,
            },
            rules: {
                useDeadline: [{ required: true, message: '请选择预计入库日期' }],
                reason: [{ required: true, message: '请填写延迟入库原因' }],
            },
            formLabelWidth: '150px'


        }
    },
    mounted() {
        this.loadDeviceClass()
        this.loadDeviceCrkInfo()
    },
    methods: {

        handleSizeChange(val) {
            this.deviceCrkFram.pageSize = val;
            this.deviceCrkFram.pageNum = 1;
            this.loadDeviceCrkInfo();
        },
        handleCurrentChange(val) {
            this.deviceCrkFram.pageNum = val;
            this.loadDeviceCrkInfo();
        },

        async loadDeviceClass() {
            await getDeviceClassByLevel(1).then(res => {
                if (res.code === '000000') {
                    this.deviceClassList = res.t
                }
            })
        },

        loadDeviceCrkInfo() {
            this.loading = true
            search(this.deviceCrkFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.deviceCrkList = res.t.list
                        this.total = res.t.total
                        var count = 1;
                        this.deviceCrkList.forEach((item) => {
                            item.seq = count++
                            const index = this.crkTypeList.findIndex(item2 => item.crkType === item2.code)
                            if (index != -1) {
                                item.crkTypeName = this.crkTypeList[index].name
                            }
                            const index2 =this.crkStatusList.findIndex(item2 => item.status === item2.code);
                            if(index2 != -1){
                                item.status_text = this.crkStatusList[index2].name
                            }
                        })
                    }
                }, 200)

            })
        },


        handleDetail({ id }) {
            this.$router.push({
                name: 'device-oi-detail',
                params: {
                    id,
                    type: this.deviceCrkFram.type
                }
            })
        },



        //搜索
        handleSearch() {
            this.loadDeviceCrkInfo(this.deviceCrkFram);
        },
        //重置
        handleReset() {
            this.deviceCrkFram = {
                type: '00',
                auxCode: null,
                classCode: null,
                auxName: null,
                auxType: null,
                auxNum: null,
                ckJbr: null,
                ckDate: null,
                rkJbr: null,
                rkDate: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.loadDeviceCrkInfo(this.deviceCrkFram);
        },

        //弹框
        //提交
        sure(deviceCrkInfo) {
            this.$refs[deviceCrkInfo].validate(valid => {
                if (valid) {
                    rkDetail(this.deviceCrkInfo).then(res => {
                        if (res.code === '000000') {
                            this.$message.success('延迟成功！')
                        }

                        this.$refs[deviceCrkInfo].resetFields()
                        this.dialogFormVisible = false

                        this.loadDeviceCrkInfo()
                    })

                }
            })
        },

        handleDelay({ id, deviceCode }) {
            this.deviceCrkInfo.id = id
            this.deviceCrkInfo.deviceCode = deviceCode
            console.log(deviceCode)
            this.dialogFormVisible = true
        },
        //关闭
        closeInfo() {
            this.$refs['deviceCrkInfo'].resetFields();
            this.dialogFormVisible = false;
            this.loadDeviceCrkInfo()
        },

        //导出
        handleDown() {
            this.$fullScreenLoading.show("导出中...");
            downloadExcel(this.deviceCrkFram, '/deviceCrk/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);
                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                        message: "导出成功~",
                        type: "success",
                    });
                }, 500)


            })
        },
        bgColor({ row }) {
            let cellStyle = '';
            const { status } = row
            if(status === '12'){
                cellStyle = 'background-color: rgb(234, 214, 125);color: #000;'
            }else if(status === '13' || status === '14'){
                cellStyle = 'background-color: rgb(237, 83, 83);color: #FFF'
            }else {
                cellStyle = 'background-color: #FFF;color: #000;' 
            }
            
            return cellStyle;

        },

    }
}
</script>

<style lang="scss" scoped>
.AuxilLedgerList {

    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>